import React, { useEffect } from "react"
import { useSignUpFindAccount } from "./context/SignUpFindAccountContext"
import queryString from "query-string"
import { useHistory, useLocation } from "react-router"
import { SignUpFooter } from "#/components/signup"
import { AppAccessPageLayout } from "#/components/access-page/AppAccessPageLayout"
import { AppAccessPageCard } from "#/components/access-page/AppAccessPageCard"
import { UnauthPreferredLocale } from "#/components/views/UnauthPreferredLocale"
import { Box, Button, BackIcon, dayjs, useTheme, Stack } from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl } from "react-intl"
import { Form, FormDatePicker, FormTextField, useFormContext } from "@carrotfertility/carotene-core-x"
import { validateMemberDOB } from "#/services/common-forms"
import { useNoEmailOnboardingForm } from "#/components/signup-shared/NoEmailOnboardingFormContext"
import { useSignUpFlow } from "#/components/context/signup/SignUpFlowContext"
import { FindAccountHeaderAndAlert } from "#/components/signup-shared/FindAccountHeaderAndAlert"

function HiddenEmail() {
  const { register } = useFormContext()
  return <input hidden {...register("email")} />
}

function SignUpFindAccountForm({ fromSignup, email }: { fromSignup?: boolean; email?: string }): JSX.Element {
  const { onSubmit, isRequestPending } = useSignUpFindAccount()
  const { errorMessage, showTryAgain } = useNoEmailOnboardingForm()
  const { firstName, lastName, dateOfBirth } = useSignUpFlow()
  const theme = useTheme()
  const intl = useIntl()
  const history = useHistory()
  const headerCopy = fromSignup
    ? intl.formatMessage({ defaultMessage: "Next, share your name and date of birth" })
    : intl.formatMessage({ defaultMessage: "Sign up for Carrot" })

  return (
    <AppAccessPageCard shake={showTryAgain || errorMessage != null}>
      <Form reValidateMode="onSubmit" defaultValues={{ email, firstName, lastName, dateOfBirth }} onSubmit={onSubmit}>
        <Stack spacing={theme.spacing(theme.tokens.spacing.lg)} marginBottom={theme.spacing(theme.tokens.spacing.md)}>
          <FindAccountHeaderAndAlert headerCopy={headerCopy} fromVerifyZipCode={false} />
          <HiddenEmail />
          <FormTextField
            name="firstName"
            registerOptions={{ required: intl.formatMessage({ defaultMessage: "Required" }) }}
            disabled={isRequestPending}
            label={intl.formatMessage({ defaultMessage: "First Name" })}
          />
          <FormTextField
            name="lastName"
            registerOptions={{ required: intl.formatMessage({ defaultMessage: "Required" }) }}
            disabled={isRequestPending}
            label={intl.formatMessage({ defaultMessage: "Last Name" })}
          />
          <FormDatePicker
            name="dateOfBirth"
            controllerProps={{
              rules: {
                required: intl.formatMessage({ defaultMessage: "Required" }),
                validate: (value: dayjs.Dayjs) => {
                  const result = validateMemberDOB(value)
                  if (typeof result !== "boolean") {
                    return intl.formatMessage(result)
                  }
                  return result
                }
              }
            }}
            label={intl.formatMessage({ defaultMessage: "Date of birth" })}
          />

          <Box
            display="flex"
            justifyContent={fromSignup ? "space-between" : "center"}
            marginTop={(theme) => theme.spacing(theme.tokens.spacing.xs)}
            sx={{ inlineSize: "100%" }}
          >
            {fromSignup ? (
              <Button
                data-testid="back-arrow"
                variant="text"
                color="secondary"
                startIcon={<BackIcon />}
                onClick={() => history.push("/signup")}
              >
                <FormattedMessage defaultMessage="Back" />
              </Button>
            ) : null}
            <Button type="submit" disabled={isRequestPending}>
              <FormattedMessage defaultMessage="Continue" />
            </Button>
          </Box>
          <Box
            display="block"
            sx={{ alignSelf: "center" }}
            paddingBlockStart={(theme) => theme.spacing(theme.tokens.spacing.xs)}
          >
            <UnauthPreferredLocale />
          </Box>
          {fromSignup ? null : (
            <Box>
              <SignUpFooter />
            </Box>
          )}
        </Stack>
      </Form>
    </AppAccessPageCard>
  )
}

export function SignUpFindAccountPage(): JSX.Element {
  const location = useLocation()
  const history = useHistory()
  const { email } = queryString.parse(location.search)
  const intl = useIntl()

  useEffect(() => {
    if (!email) {
      history.push("/signup")
    }
  }, [history, email])

  return (
    <AppAccessPageLayout title={intl.formatMessage({ defaultMessage: "Sign up" })}>
      <SignUpFindAccountForm {...{ email: Array.isArray(email) ? email[0] : email }} fromSignup />
    </AppAccessPageLayout>
  )
}

export function SignUpInfoPage(): JSX.Element {
  const intl = useIntl()

  return (
    <AppAccessPageLayout title={intl.formatMessage({ defaultMessage: "Sign up" })}>
      <SignUpFindAccountForm />
    </AppAccessPageLayout>
  )
}
