import { FormattedMessage, useIntl } from "react-intl"
import { Box, Link, Typography, useTheme } from "@carrotfertility/carotene-core"
import { FormAlert } from "@carrotfertility/carotene-core-x"
import { getErrorMessageFromCode } from "#/utils/CarrotErrors"
import { Link as RouterLink } from "react-router-dom"
import React from "react"
import { useNoEmailOnboardingForm } from "#/components/signup-shared/NoEmailOnboardingFormContext"

export function FindAccountHeaderAndAlert({
  headerCopy,
  fromVerifyZipCode
}: {
  headerCopy: string
  fromVerifyZipCode: boolean
}): JSX.Element {
  const { errorMessage, showTryAgain } = useNoEmailOnboardingForm()
  const intl = useIntl()
  const theme = useTheme()

  return (
    <>
      <Typography variant="h1">
        {showTryAgain && !fromVerifyZipCode ? <FormattedMessage defaultMessage="Try again" /> : headerCopy}
      </Typography>
      {(errorMessage || showTryAgain) && (
        <Box margin={showTryAgain ? theme.tokens.spacing.md : 0}>
          <FormAlert severity="error">
            {errorMessage ? (
              intl.formatMessage(getErrorMessageFromCode(errorMessage))
            ) : (
              <FormattedMessage
                defaultMessage="Sorry, we couldn't find your account with the information provided. Try again or <link>contact our Care Team</link> for help."
                values={{
                  link: (linkContent) => (
                    <Link component={RouterLink} color="inherit" to="/signup-get-help">
                      {linkContent}
                    </Link>
                  )
                }}
              />
            )}
          </FormAlert>
        </Box>
      )}
    </>
  )
}
