import React, { useEffect } from "react"
import { Button, Tile, Typography, useTheme } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"
import { useCarrotMatch } from "#/components/carrot-match-fertility-clinic-precision-matching/context/CarrotMatchContext"
import { getHeap } from "#/pages/groups/utils/heap"

export function CarrotMatchProviderFinderBanner(): JSX.Element {
  const theme = useTheme()
  const { updateCarrotMatchInteraction, setIsCarrotMatchDialogOpen } = useCarrotMatch()

  useEffect(() => {
    getHeap().track("CarrotMatch Fertility Clinic Precision Match PF Banner Rendered")
  }, [])

  return (
    <Tile data-id="carrot-match-provider-finder-banner">
      <Typography variant="h3" sx={{ marginBlockEnd: theme.spacing(theme.tokens.spacing.xxs) }}>
        <FormattedMessage defaultMessage="Get matched" />
      </Typography>
      <Typography variant="body1" sx={{ marginBlockEnd: theme.spacing(theme.tokens.spacing.md) }}>
        <FormattedMessage defaultMessage="Skip the scroll and see our top recommendation based on the details of your unique journey." />
      </Typography>
      <Button
        data-id="carrot-match-provider-finder-banner-get-matched-button"
        onClick={() => {
          setIsCarrotMatchDialogOpen(true)
          updateCarrotMatchInteraction({ lastQuestionViewedBeforeLeavingFlow: "Get matched" })
        }}
      >
        <FormattedMessage defaultMessage="Get my match" />
      </Button>
    </Tile>
  )
}
