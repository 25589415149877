import React from "react"
import { AppAccessPageLayout } from "#/components/access-page/AppAccessPageLayout"
import { AppAccessPageCard } from "#/components/access-page/AppAccessPageCard"
import { FormattedMessage, useIntl } from "react-intl"
import { BackIcon, Box, Button, Stack, useTheme } from "@carrotfertility/carotene-core"
import { UnauthPreferredLocale } from "../views/UnauthPreferredLocale"
import { Form, FormButton, FormTextField } from "@carrotfertility/carotene-core-x"
import { FindAccountHeaderAndAlert } from "#/components/signup-shared/FindAccountHeaderAndAlert"
import { useSignUpFlow } from "#/components/context/signup/SignUpFlowContext"

type SignUpShareZipCodeProps = {
  onSubmit: (data: { zipCode: string }) => void
  onBack: () => void
  onSkip: () => void
  isRequestPending: boolean
}

interface ZipCodeInputProps {
  enabled: boolean
  required: boolean
}

const ZipCodeInput = ({ enabled, required }: ZipCodeInputProps): JSX.Element => {
  const intl = useIntl()

  return (
    <FormTextField
      name="zipCode"
      disabled={!enabled}
      inputProps={{ readOnly: false, disabled: false }}
      registerOptions={{
        required: required ? intl.formatMessage({ defaultMessage: "Required" }) : null
      }}
      label={intl.formatMessage({ defaultMessage: "Zip Code" })}
      type="zipCode"
      id="zipCode"
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
      sx={(theme) => ({
        paddingBlockEnd: theme.spacing(theme.tokens.spacing.md)
      })}
    />
  )
}

export default function ShareZipCodePage({
  onSubmit,
  onBack,
  onSkip,
  isRequestPending
}: SignUpShareZipCodeProps): JSX.Element {
  const intl = useIntl()
  const headerCopy = intl.formatMessage({ defaultMessage: "Share your zip code" })
  const theme = useTheme()
  const { multipleEmployeesFound } = useSignUpFlow()

  return (
    <AppAccessPageLayout title={intl.formatMessage({ defaultMessage: "Share your zip code" })}>
      <AppAccessPageCard>
        <Form onSubmit={onSubmit} reValidateMode="onSubmit">
          <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
            <FindAccountHeaderAndAlert headerCopy={headerCopy} fromVerifyZipCode={true} />
            <ZipCodeInput enabled={!isRequestPending} required={!multipleEmployeesFound} />
            <Box display="flex" justifyContent={"space-between"}>
              <Button
                data-testid="back-arrow"
                onClick={onBack}
                variant="text"
                color="secondary"
                startIcon={<BackIcon />}
              >
                <FormattedMessage defaultMessage="Back" />
              </Button>
              <Stack direction="row" spacing={theme.spacing(theme.tokens.spacing.sm)}>
                {multipleEmployeesFound && (
                  <Button onClick={onSkip} color="secondary" variant="text">
                    <FormattedMessage defaultMessage="Skip" />
                  </Button>
                )}
                <FormButton type="submit" disabled={isRequestPending}>
                  <FormattedMessage defaultMessage="Continue" />
                </FormButton>
              </Stack>
            </Box>
            <Box
              display="block"
              sx={{ alignSelf: "center" }}
              paddingBlockStart={(theme) => theme.spacing(theme.tokens.spacing.xs)}
            >
              <UnauthPreferredLocale />
            </Box>
          </Stack>
        </Form>
      </AppAccessPageCard>
    </AppAccessPageLayout>
  )
}
