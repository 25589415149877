import React from "react"
import NoEmailOnboardingFormProvider from "#/components/signup-shared/NoEmailOnboardingFormContext"
import SignUpShareZipCodeContainer from "#/components/signup-share-zip-code/context/SignUpShareZipCodeContainer"

export default function SignUpShareZipCode(): JSX.Element {
  return (
    <NoEmailOnboardingFormProvider>
      <SignUpShareZipCodeContainer />
    </NoEmailOnboardingFormProvider>
  )
}
