import React from "react"
import { StateMachineProvider, StateMachineStepView } from "#/components/context/stateMachine/StateMachineV2"
import { Dialog } from "@carrotfertility/carotene-core"
import { useCarrotMatch } from "#/components/carrot-match-fertility-clinic-precision-matching/context/CarrotMatchContext"
import { carrotMatchSteps, carrotMatchWorkflows, Steps, Workflows } from "./workflows"

export function CarrotMatchDialog(): JSX.Element {
  const { isCarrotMatchDialogOpen } = useCarrotMatch()

  enum Sections {
    CARROT_MATCH = "CarrotMatch"
  }

  const carrotMatchSection = {
    name: Sections.CARROT_MATCH,
    initialStep: Steps.HAS_PARTNER_DECISION,
    desiredWorkflow: Workflows.CARROT_MATCH,
    steps: carrotMatchSteps,
    workflows: carrotMatchWorkflows
  }

  return (
    <Dialog open={isCarrotMatchDialogOpen} aria-labelledby="step-heading" id="carrot-match-dialog">
      <StateMachineProvider
        stateMachineDescription={[carrotMatchSection]}
        initialSection={Sections.CARROT_MATCH}
        onComplete={null}
      >
        <StateMachineStepView />
      </StateMachineProvider>
    </Dialog>
  )
}
