import { Redirect, Route } from "react-router-dom"
import ReadArticleContainer from "#/components/containers/library/ReadArticleContainer"
import React from "react"
import ReadLearnLandingPage from "../../read/ReadLearnLandingPage"
import SubcategoryPage from "../../read/SubcategoryPage"
import { ReadAndLearnCategoryPage } from "../../read"
import LandingPage from "../../../pages/guides/LandingPage"
import { GuideParser } from "#/pages/guides/GuideParser"
import { GroupParser } from "#/pages/groups/GroupParser"
import { createErrorBoundary } from "../../../utils/createErrorBoundary"
import { ErrorPage } from "../../views/app/ErrorPage"
import useGetCategories from "../../read/shared/useGetCategories"
import { FullScreenLoading } from "../../read/shared/FullScreenLoading"
import GroupLandingPage from "#/pages/groups/GroupLandingPage"
import { useConditionDepsStatus } from "#/services/contentful/hooks/use-contentful-conditions"
import { Switch } from "#/services/routing/Switch"
import {
  NewbornToParentingRedirects,
  SplitParentingFromPregnancyArticle,
  SplitParentingFromPregnancyGroup
} from "../../../utils/Paths"
import { CountryCode } from "../../../utils/CountryCode"
import { useCurrentUser } from "../../context/user/UserContext"

const LibraryRouter = (): JSX.Element => {
  const LibraryErrorBoundary = createErrorBoundary(ErrorPage)
  const conditionDeps = useConditionDepsStatus()
  const { isLoading: isCategoriesLoading } = useGetCategories()
  const user = useCurrentUser()

  if (conditionDeps.isError) {
    throw conditionDeps.error
  }

  if (conditionDeps.isLoading || isCategoriesLoading) {
    return <FullScreenLoading />
  }

  return (
    <LibraryErrorBoundary>
      <Switch>
        <Route exact path="/read" component={ReadLearnLandingPage} />
        <Redirect
          exact
          path="/read/Fertility-basics/Introducing-the-Ava-Bracelet"
          to="/your-benefit-guide/coverage/ava-bracelet"
        />
        {SplitParentingFromPregnancyArticle.map(({ from, to }) => (
          <Redirect key={from} {...{ from, to }} />
        ))}
        {user.company.countryCode === CountryCode.United_States &&
          SplitParentingFromPregnancyGroup.map(({ from, to }) => <Redirect key={from} {...{ from, to }} />)}
        {NewbornToParentingRedirects.map(({ from, to }) => (
          <Redirect key={from} {...{ from, to }} />
        ))}
        <Route exact path="/read/:category?" component={ReadAndLearnCategoryPage} />
        <Route exact path="/read/:category/:article" component={ReadArticleContainer} />
        <Route
          exact
          path="/read/:category/guide/:guide"
          component={() => <GuideParser GuideComponent={LandingPage} />}
        />
        <Route
          exact
          path="/read/:category/group/:group"
          component={() => <GroupParser GroupComponent={GroupLandingPage} />}
        />
        <Route exact path="/read/all-articles/:category/:subCategory" component={SubcategoryPage} />

        <Redirect to={`/read`} />
      </Switch>
    </LibraryErrorBoundary>
  )
}

export { LibraryRouter }
