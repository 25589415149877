import { Box, Button, BackIcon, Stack, useTheme, dayjs, Divider } from "@carrotfertility/carotene-core"
import { Form, FormDatePicker, FormTextField, useFormContext } from "@carrotfertility/carotene-core-x"
import { AppAccessPageCard } from "#/components/access-page/AppAccessPageCard"
import { AppAccessPageLayout } from "#/components/access-page/AppAccessPageLayout"
import { SignUpFooter } from "#/components/signup"
import { UnauthPreferredLocale } from "#/components/views/UnauthPreferredLocale"
import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useHistory } from "react-router"
import { validateMemberDOB } from "#/services/common-forms"
import { useNoEmailOnboardingForm } from "#/components/signup-shared/NoEmailOnboardingFormContext"
import { FindAccountHeaderAndAlert } from "#/components/signup-shared/FindAccountHeaderAndAlert"

type FindAccountByEmployeeIdProps = {
  parentCompanyId: number
  fromSignup?: boolean
  email?: string | string[]
  onSubmit: ({
    externalEmployeeId,
    parentCompanyId,
    dateOfBirth
  }: {
    externalEmployeeId: string
    parentCompanyId: number
    dateOfBirth: dayjs.Dayjs
  }) => Promise<void>
  isRequestPending: boolean
}

function HiddenParentCompanyId() {
  const { register } = useFormContext()
  return <input hidden {...register("parentCompanyId")} />
}

export function FindAccountByEmployeeIdPage({
  parentCompanyId,
  fromSignup,
  onSubmit,
  isRequestPending
}: FindAccountByEmployeeIdProps): JSX.Element {
  const { errorMessage, showTryAgain } = useNoEmailOnboardingForm()
  const theme = useTheme()
  const intl = useIntl()
  const history = useHistory()
  const headerCopy = fromSignup
    ? intl.formatMessage({ defaultMessage: "Next, share your name and date of birth" })
    : intl.formatMessage({ defaultMessage: "Sign up for Carrot" })

  return (
    <AppAccessPageLayout title={intl.formatMessage({ defaultMessage: "Sign up" })}>
      <AppAccessPageCard shake={showTryAgain || errorMessage != null}>
        <Form reValidateMode="onSubmit" defaultValues={{ parentCompanyId }} onSubmit={onSubmit}>
          <Stack spacing={theme.spacing(theme.tokens.spacing.lg)} marginBottom={theme.spacing(theme.tokens.spacing.md)}>
            <FindAccountHeaderAndAlert headerCopy={headerCopy} fromVerifyZipCode={false} />
            <FormTextField
              name="externalEmployeeId"
              registerOptions={{ required: intl.formatMessage({ defaultMessage: "Required" }) }}
              disabled={isRequestPending}
              label={intl.formatMessage({ defaultMessage: "Employee ID" })}
            />
            <HiddenParentCompanyId />
            <FormDatePicker
              name="dateOfBirth"
              controllerProps={{
                rules: {
                  required: intl.formatMessage({ defaultMessage: "Required" }),
                  validate: (value: dayjs.Dayjs) => {
                    const result = validateMemberDOB(value)
                    if (typeof result !== "boolean") {
                      return intl.formatMessage(result)
                    }
                    return result
                  }
                }
              }}
              label={intl.formatMessage({ defaultMessage: "Date of birth" })}
            />

            <Box
              display="flex"
              justifyContent={fromSignup ? "space-between" : "center"}
              marginTop={(theme) => theme.spacing(theme.tokens.spacing.xs)}
              sx={{ inlineSize: "100%" }}
            >
              {fromSignup ? (
                <Button
                  data-testid="back-arrow"
                  variant="text"
                  color="secondary"
                  startIcon={<BackIcon />}
                  onClick={() => history.push("/signup")}
                >
                  <FormattedMessage defaultMessage="Back" />
                </Button>
              ) : null}
              <Button type="submit" disabled={isRequestPending}>
                <FormattedMessage defaultMessage="Continue" />
              </Button>
            </Box>
            <Box display="block" sx={{ alignSelf: "center" }}>
              <UnauthPreferredLocale />
            </Box>

            <Box display="block" sx={{ alignSelf: "center", inlineSize: "40%" }}>
              <Divider>
                <FormattedMessage defaultMessage="or sign up with" />
              </Divider>
            </Box>

            <Box
              display="flex"
              justifyContent={"space-between"}
              marginTop={(theme) => theme.spacing(theme.tokens.spacing.xs)}
              sx={{ inlineSize: "100%" }}
            >
              <Button
                data-testid="signup-email"
                variant="outlined"
                color="secondary"
                sx={{ flex: 1 }}
                onClick={() => history.push("/signup")}
              >
                <FormattedMessage defaultMessage="Email" />
              </Button>
              <Button
                data-testid="signup-name-dob"
                variant="outlined"
                color="secondary"
                sx={{ flex: 1, marginInlineStart: 5 }}
                onClick={() => history.push("/signup/info")}
              >
                <FormattedMessage defaultMessage="Name / DOB" />
              </Button>
            </Box>

            <Box display="block" sx={{ alignSelf: "center", inlineSize: "50%" }}>
              <Divider />
            </Box>

            {fromSignup ? null : (
              <Box>
                <SignUpFooter />
              </Box>
            )}
          </Stack>
        </Form>
      </AppAccessPageCard>
    </AppAccessPageLayout>
  )
}
