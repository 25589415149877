import { useHistory } from "react-router-dom"
import { SignUpFlowMemberInfo, useSignUpFlow } from "#/components/context/signup/SignUpFlowContext"
import React, { ReactNode, useContext } from "react"
import { trackMatomoPageView } from "#/utils/matomo"
import { isHttpError } from "#/utils/HttpErrors"
import { CarrotErrorCodes } from "#/utils/CarrotErrors"
import { reportError } from "#/utils/ErrorReporting"

type NoEmailOnboardingFormState = {
  onEmailFound: (email: string) => void
  onEmailNotFound: (signUpInfo: SignUpFlowMemberInfo) => void
  onEmployeeAlreadyRegistered: () => void
  onEmployeeHasSamlAccount: (email: string) => void
  onError: (error: any, signUpInfo: SignUpFlowMemberInfo) => void
  errorMessage: string
  showTryAgain: boolean
}

interface ProviderProps {
  children: ReactNode
}

export type MemberInfo = {
  employeeId?: number
  existingEmail?: string
  samlIdpEntityId?: string
  samlExternalLoginUrl?: string
  ssoOnly: boolean
  employeeAlreadyRegistered: boolean
  requiresZipCodeVerification: boolean
  hasChannelMemberId: boolean
}

const NoEmailOnboardingFormContext = React.createContext<NoEmailOnboardingFormState>(null)

export const useNoEmailOnboardingForm = () => useContext(NoEmailOnboardingFormContext)

export default function NoEmailOnboardingFormProvider({ children }: ProviderProps): JSX.Element {
  const history = useHistory()
  const { handleSaveMemberInfoInMemory, saveMultipleEmployeesFound } = useSignUpFlow()
  const [showTryAgain, setShowTryAgain] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState(null)

  const onEmailFound = (email: string): void => {
    const signupSuccessHref = `/signup-success?email=${encodeURIComponent(email)}`
    history.push(signupSuccessHref)
    trackMatomoPageView(signupSuccessHref)
  }

  const onEmailNotFound = ({
    email,
    dateOfBirth,
    firstName,
    lastName,
    externalEmployeeId,
    parentCompanyId
  }: SignUpFlowMemberInfo): void => {
    handleSaveMemberInfoInMemory({ email, dateOfBirth, firstName, lastName, externalEmployeeId, parentCompanyId })
    history.push(
      parentCompanyId ? "/signup-find-account-by-external-employee-id-success" : "/signup-find-account-success"
    )
  }

  const onEmployeeAlreadyRegistered = (): void => {
    history.push("/signup-employee-already-registered")
  }

  const onEmployeeHasSamlAccount = (existingEmail: string): void => {
    handleSaveMemberInfoInMemory({ email: existingEmail })
    history.push("/signup-employee-has-saml")
  }

  const onError = async (
    error: any,
    { email, dateOfBirth, firstName, lastName, externalEmployeeId, parentCompanyId }: SignUpFlowMemberInfo
  ): Promise<void> => {
    handleSaveMemberInfoInMemory({ email, dateOfBirth, firstName, lastName, externalEmployeeId, parentCompanyId })
    if (isHttpError(error)) {
      const { code } = await error.response.json()
      if (code === CarrotErrorCodes.EMPLOYEE_NOT_FOUND) {
        setShowTryAgain(true)
        return
      }
      if (code === CarrotErrorCodes.MULTIPLE_EMPLOYEES_FOUND) {
        history.push("/signup-get-help")
        return
      }
      if (code === CarrotErrorCodes.MULTIPLE_EMPLOYEES_FOUND_CHECK_ZIP_CODE) {
        saveMultipleEmployeesFound()
        history.push("/signup-share-zip-code")
        return
      }
    }

    reportError(error)
    setErrorMessage(CarrotErrorCodes.UNEXPECTED_ERROR)
    return
  }

  return (
    <NoEmailOnboardingFormContext.Provider
      value={{
        onEmailFound,
        onEmailNotFound,
        onEmployeeAlreadyRegistered,
        onEmployeeHasSamlAccount,
        onError,
        showTryAgain,
        errorMessage
      }}
    >
      {children}
    </NoEmailOnboardingFormContext.Provider>
  )
}
