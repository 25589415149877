import React, { ReactNode, useContext, useEffect } from "react"
import { SignUpFlowMemberInfo, useSignUpFlow } from "../../context/signup/SignUpFlowContext"
import { getHeap } from "#/utils/heap"
import { redirectToSamlExternalLogin } from "#/utils/RedirectToSamlExternalLogin"
import { useIntl } from "react-intl"
import { carrotClient } from "#/utils/CarrotClient"
import { useMutation, UseMutationResult } from "@tanstack/react-query"
import { MemberInfo, useNoEmailOnboardingForm } from "#/components/signup-shared/NoEmailOnboardingFormContext"
import { useHistory } from "react-router-dom"

export type SignUpFirstLastDateOfBirthPayload = {
  email: string
  firstName: string
  lastName: string
  dateOfBirth: string
  // eslint-disable-next-line no-restricted-syntax -- This is the user's preferredLocale based on their selection during registration. Carrot-app's LocaleService will then determine if they can use the selected language once they sign up.
  preferredLocale: string
}

interface SignUpFindAccountProviderProps {
  onSubmit: (signUpInfo: SignUpFlowMemberInfo) => Promise<void>
  isRequestPending: boolean
}

const SignUpFindAccountContext = React.createContext<SignUpFindAccountProviderProps>(null)

export const useSignUpFindAccount = () => useContext(SignUpFindAccountContext)

const useFindAccountMutation = (): UseMutationResult<MemberInfo, Error, SignUpFirstLastDateOfBirthPayload> => {
  return useMutation(
    (payload: SignUpFirstLastDateOfBirthPayload): Promise<MemberInfo> =>
      carrotClient.signUpFirstLastDateOfBirth(payload)
  )
}

export function SignUpFindAccountProvider({ children }: { children: ReactNode }) {
  const { handleSaveMemberInfoInMemory, clearMultipleEmployeesFound } = useSignUpFlow()
  const { onEmailFound, onEmailNotFound, onEmployeeAlreadyRegistered, onEmployeeHasSamlAccount, onError } =
    useNoEmailOnboardingForm()
  const findAccountMutation = useFindAccountMutation()
  const history = useHistory()
  const { locale } = useIntl()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => clearMultipleEmployeesFound(), [])

  const onFindAccountSuccess = (
    memberInfo: MemberInfo,
    { firstName, lastName, dateOfBirth, email }: SignUpFlowMemberInfo
  ) => {
    if (memberInfo.samlIdpEntityId && memberInfo.existingEmail) {
      onEmployeeHasSamlAccount(memberInfo.existingEmail)
      return
    } else if (memberInfo.samlIdpEntityId && memberInfo.ssoOnly && !memberInfo.existingEmail) {
      // SSO Enabled No Email Onboarding member must authenticate before providing their email.
      redirectToSamlExternalLogin(memberInfo.samlExternalLoginUrl, memberInfo.samlIdpEntityId, false)
      return
    }

    if (memberInfo.existingEmail) {
      if (!memberInfo.employeeAlreadyRegistered) {
        onEmailFound(memberInfo.existingEmail)
      } else {
        handleSaveMemberInfoInMemory({ email: memberInfo.existingEmail })
        onEmployeeAlreadyRegistered()
      }
    } else if (memberInfo.requiresZipCodeVerification) {
      handleSaveMemberInfoInMemory({ email, dateOfBirth, firstName, lastName })
      history.push("/signup-share-zip-code")
    } else {
      onEmailNotFound({ email, dateOfBirth, firstName, lastName })
    }
  }

  const onSubmit = async ({ firstName, lastName, dateOfBirth, email }: SignUpFlowMemberInfo) => {
    getHeap().track("NoEmailOnboarding", { EventName: "submit name & DOB for signup" })
    findAccountMutation.mutate(
      // eslint-disable-next-line no-restricted-syntax -- This is the user's preferredLocale based on their selection during registration. Carrot-app's LocaleService will then determine if they can use the selected language once they sign up.
      { email, firstName, lastName, dateOfBirth: dateOfBirth.format("YYYY-MM-DD"), preferredLocale: locale },
      {
        onSuccess: (data: MemberInfo) => onFindAccountSuccess(data, { firstName, lastName, dateOfBirth, email }),
        onError: (error: any) => {
          onError(error, { firstName, lastName, dateOfBirth, email })
        }
      }
    )
  }

  return (
    <SignUpFindAccountContext.Provider value={{ onSubmit, isRequestPending: findAccountMutation.isLoading }}>
      {children}
    </SignUpFindAccountContext.Provider>
  )
}
