import React, { useState } from "react"
import { useSignUpFlow } from "../context/signup/SignUpFlowContext"
import { useSignUpGetHelp } from "./context/SignUpGetHelpContext"
import SignUpGetHelpSuccess from "../../pages/signup-get-help-success"
import { AppAccessPageLayout } from "#/components/access-page/AppAccessPageLayout"
import { AppAccessPageCard } from "#/components/access-page/AppAccessPageCard"
import { FormattedMessage, defineMessage, useIntl } from "react-intl"
import { getErrorMessageFromCode } from "#/utils/CarrotErrors"
import { Alert, Box, Button, BackIcon, Stack, Typography, dayjs, useTheme } from "@carrotfertility/carotene-core"
import { UnauthPreferredLocale } from "../views/UnauthPreferredLocale"
import {
  Form,
  FormAutocomplete,
  FormButton,
  FormDatePicker,
  FormRadioGroup,
  FormSelect,
  FormTextField
} from "@carrotfertility/carotene-core-x"
import { validateMemberDOB } from "#/services/common-forms"
import { useHistory } from "react-router"
import { useIntlSort } from "#/utils/hooks/useIntlSort"
import { CountryCodes } from "#/utils/CountryCode"
import FormPhoneNumberTextInput from "#/components/phone-number-collection/FormPhoneNumberTextInput"
import {
  addCountryOptionsEdgeCases,
  usePhoneCodeSelectOptions
} from "#/components/phone-number-collection/usePhoneCodeSelectOptions"

function SignupGetHelpHeader(): JSX.Element {
  const { errorMessage } = useSignUpGetHelp()
  const { multipleEmployeesFound } = useSignUpFlow()
  const intl = useIntl()
  const theme = useTheme()
  const description = multipleEmployeesFound
    ? defineMessage({
        defaultMessage:
          "We’re having trouble finding your account. Please provide the information below and our Care Team will reach out with next steps."
      })
    : defineMessage({
        defaultMessage:
          "Our Care Team will use this information to look for your account. If you recently got access to Carrot, it may take a few weeks for your account to be activated."
      })

  return (
    <Stack spacing={theme.spacing(theme.tokens.spacing.xs)}>
      <Typography variant="h1">
        <FormattedMessage defaultMessage="Get help" />
      </Typography>
      {errorMessage ? (
        <Alert severity="error">{intl.formatMessage(getErrorMessageFromCode(errorMessage))}</Alert>
      ) : (
        <Typography>{intl.formatMessage(description)}</Typography>
      )}
    </Stack>
  )
}

type CountryOption = { label: string; value: string }

const SignUpGetHelpForm = (): JSX.Element => {
  const { shake, onSubmit, success } = useSignUpGetHelp()
  const {
    dateOfBirth,
    email,
    firstName,
    lastName,
    country,
    benefitSponsor,
    employeeOrPlanId,
    phoneNumber,
    phoneNumberCountryCode,
    contactMethod,
    multipleEmployeesFound
  } = useSignUpFlow()
  const theme = useTheme()
  const intl = useIntl()
  const history = useHistory()
  const { localeComparator } = useIntlSort()
  const [prefContactMethod, setPrefContactMethod] = useState<string | null>(contactMethod ?? null)
  const phoneCodeSelectOptions = usePhoneCodeSelectOptions()

  function getCountryOptionLabel(countryCode: string): string {
    return new Intl.DisplayNames([intl.locale], { type: "region" }).of(countryCode)
  }

  const CountryOptions: CountryOption[] = CountryCodes.map((code) => {
    const countryName = getCountryOptionLabel(code)
    return {
      label: countryName,
      value: code
    }
  })
  const countryOptionsWithEdgeCases = addCountryOptionsEdgeCases(CountryOptions, intl).sort(
    (a: CountryOption, b: CountryOption) => localeComparator(a.label, b.label)
  )

  const isPhoneNumberValid = (value: string) => {
    if (!value) return true
    const hasLetters = /[a-zA-Z]/.test(value)
    const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/
    const isValidFormat = phoneNumberPattern.test(value.replace(/[^0-9+]/g, ""))

    return (!hasLetters && isValidFormat) || intl.formatMessage({ defaultMessage: "Please enter a valid phone number" })
  }

  return success ? (
    <SignUpGetHelpSuccess />
  ) : (
    <AppAccessPageCard shake={shake}>
      <Form
        reValidateMode="onChange"
        defaultValues={{
          email,
          dateOfBirth,
          firstName,
          lastName,
          country,
          benefitSponsor,
          employeeOrPlanId,
          phoneNumber,
          phoneNumberCountryCode,
          contactMethod
        }}
        onSubmit={onSubmit}
      >
        <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
          <SignupGetHelpHeader />
          <FormTextField
            name="firstName"
            registerOptions={{ required: intl.formatMessage({ defaultMessage: "Required" }) }}
            id="firstName"
            label={intl.formatMessage({ defaultMessage: "First Name" })}
          />
          <FormTextField
            name="lastName"
            registerOptions={{ required: intl.formatMessage({ defaultMessage: "Required" }) }}
            id="lastName"
            label={intl.formatMessage({ defaultMessage: "Last Name" })}
          />
          <FormDatePicker
            name="dateOfBirth"
            controllerProps={{
              rules: {
                required: intl.formatMessage({ defaultMessage: "Required" }),
                validate: (value: dayjs.Dayjs) => {
                  const result = validateMemberDOB(value)
                  if (typeof result !== "boolean") {
                    return intl.formatMessage(result)
                  }
                  return result
                }
              }
            }}
            label={intl.formatMessage({ defaultMessage: "Date of birth" })}
          />
          <FormTextField
            label={intl.formatMessage({ defaultMessage: "Benefit Sponsor" })}
            name="benefitSponsor"
            registerOptions={{ required: intl.formatMessage({ defaultMessage: "Required" }) }}
            helperText={intl.formatMessage({
              defaultMessage: "This may be your employer, health plan, or another way you get your Carrot benefit."
            })}
          />
          <FormAutocomplete
            name="country"
            data-testid={"country-label"}
            label={intl.formatMessage({ defaultMessage: "Your country" })}
            controllerProps={{ rules: { required: intl.formatMessage({ defaultMessage: "Required" }) } }}
            options={countryOptionsWithEdgeCases}
          />
          <FormTextField
            name="employeeOrPlanId"
            label={intl.formatMessage({ defaultMessage: "Employee or Health Plan ID" })}
            helperText={intl.formatMessage({
              defaultMessage:
                "Enter your employee ID if you get Carrot through your employer or your health plan ID if you get it through your health plan."
            })}
          />
          <FormTextField
            name="email"
            registerOptions={{ required: intl.formatMessage({ defaultMessage: "Required" }) }}
            label={intl.formatMessage({ defaultMessage: "Email" })}
            id="email"
            type={"email"}
          />
          <FormSelect
            name={"phoneNumberCountryCode"}
            label={intl.formatMessage({ defaultMessage: "Country Code" })}
            options={phoneCodeSelectOptions}
            controllerProps={{
              rules: {
                required:
                  prefContactMethod === "phone"
                    ? intl.formatMessage({ defaultMessage: "Country code required" })
                    : false
              }
            }}
          />
          <FormPhoneNumberTextInput
            name="phoneNumber"
            countryCodeFieldName={"phoneNumberCountryCode"}
            label={intl.formatMessage({ defaultMessage: "Phone Number" })}
            registerOptions={{
              required:
                prefContactMethod === "phone" ? intl.formatMessage({ defaultMessage: "Phone number required" }) : false,
              validate: (value: string) => isPhoneNumberValid(value)
            }}
          />
          <FormRadioGroup
            controllerProps={{ rules: { required: intl.formatMessage({ defaultMessage: "Required" }) } }}
            onChange={(e: React.FormEvent<HTMLFieldSetElement>) =>
              setPrefContactMethod((e.target as HTMLInputElement).value)
            }
            name="contactMethod"
            label={intl.formatMessage({ defaultMessage: "How should we contact you?" })}
            options={[
              { label: intl.formatMessage({ defaultMessage: "Email" }), value: "email" },
              { label: intl.formatMessage({ defaultMessage: "Phone" }), value: "phone" }
            ]}
          />
          <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
            <Box display="flex" justifyContent={"space-between"}>
              <Button
                onClick={() =>
                  email && !multipleEmployeesFound
                    ? history.push(`/signup-find-account?email=${encodeURIComponent(email)}`)
                    : history.goBack()
                }
                variant="text"
                color="secondary"
                startIcon={<BackIcon />}
              >
                <FormattedMessage defaultMessage="Back" />
              </Button>
              <FormButton type="submit">{intl.formatMessage({ defaultMessage: "Find account" })}</FormButton>
            </Box>
            <Box display="block" sx={{ alignSelf: "center" }}>
              <UnauthPreferredLocale />
            </Box>
          </Stack>
        </Stack>
      </Form>
    </AppAccessPageCard>
  )
}

const SignUpGetHelpPage = (): JSX.Element => {
  const intl = useIntl()
  return (
    <AppAccessPageLayout title={intl.formatMessage({ defaultMessage: "Sign up" })}>
      <SignUpGetHelpForm />
    </AppAccessPageLayout>
  )
}

export default SignUpGetHelpPage
