import React from "react"
import { SignUpInfoPage } from "#/components/signup-find-account"
import { SignUpFindAccountProvider } from "#/components/signup-find-account/context/SignUpFindAccountContext"
import { useParams } from "react-router-dom"
import { FindAccountByExternalEmployeeIdContainer } from "#/components/sign-up-find-account-company-specific/context/FindAccountByExternalEmployeeIdContainer"
import NoEmailOnboardingFormProvider from "#/components/signup-shared/NoEmailOnboardingFormContext"

export default function SignUpInfo(): JSX.Element {
  const { parentCompanyId } = useParams<{ parentCompanyId: string }>()

  return (
    <>
      <NoEmailOnboardingFormProvider>
        <>
          {parentCompanyId ? (
            <FindAccountByExternalEmployeeIdContainer parentCompanyId={Number(parentCompanyId)} />
          ) : (
            <SignUpFindAccountProvider>
              <SignUpInfoPage />
            </SignUpFindAccountProvider>
          )}
        </>
      </NoEmailOnboardingFormProvider>
    </>
  )
}
