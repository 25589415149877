import React, { useReducer } from "react"
import { redirectToSamlExternalLogin } from "#/utils/RedirectToSamlExternalLogin"
import { CarrotErrorCodes } from "#/utils/CarrotErrors"
import { useHistory } from "react-router"
import { carrotClient } from "#/utils/CarrotClient"
import { dayjs } from "@carrotfertility/carotene-core"

export type SignUpFlowMemberInfo = {
  email?: string
  dateOfBirth?: dayjs.Dayjs
  firstName?: string
  lastName?: string
  externalEmployeeId?: string
  parentCompanyId?: number
  country?: { value: string; label: string }
  benefitSponsor?: string
  employeeOrPlanId?: string
  phoneNumberCountryCode?: { value: string; label: string }
  phoneNumber?: string
  contactMethod?: string
}

interface SignUpFlowState extends SignUpFlowMemberInfo {
  multipleEmployeesFound?: boolean
}

interface SignUpFlowProviderProps extends SignUpFlowState {
  children: React.ReactNode | React.ReactNode[]
}

const SignUpFlowContext = React.createContext(null)

export const useSignUpFlow = () => React.useContext(SignUpFlowContext)

const actionTypes = {
  saveMember: "SAVE_MEMBER",
  multipleEmployeesFound: "MULTIPLE_FOUND",
  clearMultipleEmployeesFound: "CLEAR_MULTIPLE_FOUND"
}

function signUpFlowReducer(state: SignUpFlowState, action: { type: string; payload?: SignUpFlowMemberInfo }) {
  switch (action.type) {
    case actionTypes.saveMember: {
      return {
        ...state,
        email: action.payload.email,
        dateOfBirth: action.payload.dateOfBirth,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        externalEmployeeId: action.payload.externalEmployeeId,
        parentCompanyId: action.payload.parentCompanyId,
        country: action.payload.country,
        benefitSponsor: action.payload.benefitSponsor,
        employeeOrPlanId: action.payload.employeeOrPlanId,
        phoneNumber: action.payload.phoneNumber,
        contactMethod: action.payload.contactMethod
      }
    }
    case actionTypes.multipleEmployeesFound: {
      return {
        ...state,
        multipleEmployeesFound: true
      }
    }
    case actionTypes.clearMultipleEmployeesFound: {
      return {
        ...state,
        multipleEmployeesFound: false
      }
    }
    default: {
      throw new Error(`Unhandled type: ${action.type}`)
    }
  }
}

export default function SignUpFlowProvider({
  children,
  email,
  dateOfBirth,
  firstName,
  lastName,
  externalEmployeeId,
  parentCompanyId,
  country,
  benefitSponsor,
  employeeOrPlanId,
  phoneNumber,
  phoneNumberCountryCode,
  contactMethod,
  multipleEmployeesFound
}: SignUpFlowProviderProps): JSX.Element {
  const [state, dispatch] = useReducer(signUpFlowReducer, {
    email: email || null,
    dateOfBirth: dateOfBirth || null,
    firstName: firstName || null,
    lastName: lastName || null,
    multipleEmployeesFound: Boolean(multipleEmployeesFound),
    externalEmployeeId: externalEmployeeId || null,
    parentCompanyId: parentCompanyId || null,
    country: country || null,
    benefitSponsor: benefitSponsor || null,
    employeeOrPlanId: employeeOrPlanId || null,
    phoneNumber: phoneNumber || null,
    phoneNumberCountryCode: phoneNumberCountryCode || null,
    contactMethod: contactMethod || null
  })
  const history = useHistory()

  const handleSaveMemberInfoInMemory = (memberInfo: SignUpFlowMemberInfo): void => {
    return dispatch({ type: actionTypes.saveMember, payload: memberInfo })
  }
  const saveMultipleEmployeesFound = (): void => dispatch({ type: actionTypes.multipleEmployeesFound })
  const clearMultipleEmployeesFound = (): void => dispatch({ type: actionTypes.clearMultipleEmployeesFound })
  function getHasFoundAccount(): boolean {
    return (
      (state.email != null && state.dateOfBirth != null && state.firstName != null && state.lastName != null) ||
      (state.externalEmployeeId != null && state.dateOfBirth != null)
    )
  }

  const lookupAccountByEmail = async (email: string) => {
    try {
      const response = await carrotClient.lookup(email)

      if (response?.employee?.samlIdpEntityId && response?.employee?.ssoOnly) {
        const { samlExternalLoginUrl, samlIdpEntityId, isAdmin } = response.employee
        redirectToSamlExternalLogin(samlExternalLoginUrl, samlIdpEntityId, isAdmin)
        return
      }
      if (response?.employee?.isRegistered) {
        handleSaveMemberInfoInMemory({ email })
        history.push("/signup-employee-already-registered")
        return
      }

      return response.status
    } catch (error) {
      return CarrotErrorCodes.INTERNAL_SERVER_ERROR
    }
  }

  return (
    <SignUpFlowContext.Provider
      value={{
        getHasFoundAccount,
        lookupAccountByEmail,
        handleSaveMemberInfoInMemory,
        saveMultipleEmployeesFound,
        clearMultipleEmployeesFound,
        ...state
      }}
    >
      {children}
    </SignUpFlowContext.Provider>
  )
}
