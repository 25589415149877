import React from "react"
import { SignUpFindAccountPage } from "#/components/signup-find-account"
import { SignUpFindAccountProvider } from "#/components/signup-find-account/context/SignUpFindAccountContext"
import NoEmailOnboardingFormProvider from "#/components/signup-shared/NoEmailOnboardingFormContext"

export default function SignUpFindAccount(): JSX.Element {
  return (
    <NoEmailOnboardingFormProvider>
      <SignUpFindAccountProvider>
        <SignUpFindAccountPage />
      </SignUpFindAccountProvider>
    </NoEmailOnboardingFormProvider>
  )
}
